import {createRouter, createWebHashHistory} from 'vue-router'
//import HomeView from '../views/HomeView.vue'
//import LoginView from "@/views/LoginView";
//import MenuView from "@/views/MenuView";
//import UserCreateView from "@/views/UserCreateView";
//import UserView from "@/views/UserView";
//import RoomsMyView from "@/views/RoomsMyView";
//import RoomView from "@/views/RoomView";
//import RoomCreateView from "@/views/RoomCreateView";
//import BuyView from "@/views/BuyView";
//import PayOutView from "@/views/PayOutView";
//import PasswordResetSendView from "@/views/PasswordResetSendView";
//import PasswordResetView from "@/views/PasswordResetView";
//import MySessionView from "@/views/MySessionView";
//import SupportView from "@/views/SupportView";
//import PrivacyPolicy from "@/views/PrivacyPolicy";
//import TermsOfService from "@/views/TermsOfService";
//import InfoView from "@/views/InfoView";
//import BlockView from "@/views/BlockView";
//import CheckMailView from "@/views/CheckMailView";
//import EmailVerifiedView from "@/views/EmailVerifiedView";

const routes = [

    {
        path: '/',
        name: 'home',
//        component: HomeView,
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
    },
    {
        path: '/info/:id?',
        name: 'info',
//        component: InfoView
        component: () => import(/* webpackChunkName: "InfoView" */ '../views/InfoView.vue')
    },
    {
        path: '/privacy_policy',
        name: 'privacy_policy',
//        component: PrivacyPolicy
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
    },
    {
        path: '/tos',
        name: 'terms_of_service',
//        component: TermsOfService
        component: () => import(/* webpackChunkName: "TermsOfService" */ '../views/TermsOfService.vue')
    },
    {
        path: '/login',
        name: 'login',
//        component: LoginView
        component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
    },
    {
        path: '/user_create',
        name: 'user_create',
//        component: UserCreateView
        component: () => import(/* webpackChunkName: "UserCreateView" */ '../views/UserCreateView.vue')
    },
    {
        path: '/user_create_check_mail',
        name: 'user_create_check_mail',
//        component: CheckMailView
        component: () => import(/* webpackChunkName: "CheckMailView" */ '../views/CheckMailView.vue')
    },
    {
        path: '/user_create_verify_email/:uuid',
        name: "user_create_verify_email",
//        component: EmailVerifiedView
        component: () => import(/* webpackChunkName: "EmailVerifiedView" */ '../views/EmailVerifiedView.vue')
    },
    {
        path: '/rooms_my/:user_id?',
        name: 'rooms_my',
//        component: RoomsMyView
        component: () => import(/* webpackChunkName: "RoomsMyView" */ '../views/RoomsMyView.vue')
    },
    {
        path: '/room/:roomid',
        name: 'room',
//        component: RoomView
        component: () => import(/* webpackChunkName: "room" */ '../views/RoomView.vue')
    },
    {
        path: '/room_create',
        name: 'room_create',
//        component: RoomCreateView
        component: () => import(/* webpackChunkName: "RoomCreateView" */ '../views/RoomCreateView.vue')
    },
    {
        path: '/user/:user_id',
        name: 'user',
//        component: UserView
        component: () => import(/* webpackChunkName: "UserView" */ '../views/UserView.vue')
    },
    {
        path: '/userblocks',
        name: 'userblocks',
//        component: BlockView
        component: () => import(/* webpackChunkName: "BlockView" */ '../views/BlockView.vue')
    },
    {
        path: '/buycoin',
        name: "buycoin",
//        component: BuyView
        component: () => import(/* webpackChunkName: "BuyView" */ '../views/BuyViewFree.vue')
    },
//    {
//        path: '/buycoin',
//        name: "buycoin",
////        component: BuyView
//        component: () => import(/* webpackChunkName: "BuyView" */ '../views/BuyView.vue')
//    },
    {
        path: '/menu',
        name: 'menu',
//        component: MenuView
        component: () => import(/* webpackChunkName: "MenuView" */ '../views/MenuView.vue')
    },
//    {
//        path: '/payout',
//        home: 'payout',
////        component: PayOutView
//        component: () => import(/* webpackChunkName: "PayOutView" */ '../views/PayOutView.vue')
//    },
    {
        path: '/my_session',
        home: 'my_session',
//        component: MySessionView
        component: () => import(/* webpackChunkName: "MySessionView" */ '../views/MySessionView.vue')
    },
    {
        path: '/password_reset_send',
        name: "password_reset_send",
//        component: PasswordResetSendView
        component: () => import(/* webpackChunkName: "PasswordResetSendView" */ '../views/PasswordResetSendView.vue')
    },
    {
        path: '/password_reset/:uuid',
        name: "password_reset",
//        component: PasswordResetView
        component: () => import(/* webpackChunkName: "PasswordResetView" */ '../views/PasswordResetView.vue')
    },
    {
        path: '/support',
        name: 'support',
//        component: SupportView
        component: () => import(/* webpackChunkName: "support" */ '../views/SupportView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
