import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';
import axios from 'axios';

const store = createStore({
    plugins: [createPersistedState()],
    state: {
        refresh_token: '',
        access_token: '',
        loggedInUser: {},
        isAuthenticated: false,
        wantTogo: '',
    },
    mutations: {
        setRefreshToken: function (state, refreshToken) {
            state.refresh_token = refreshToken;
            state.isAuthenticated = true;
        },
        setAccessToken: function (state, accessToken) {
            state.access_token = accessToken;
            state.isAuthenticated = true;
        },
        // sets state with user information and toggles
        // isAuthenticated from false to true
        setLoggedInUser: function (state, user) {
            state.loggedInUser = user;
            state.isAuthenticated = true;
        },
        // delete all auth and user information from the state
        clearUserData: function (state) {
            state.refresh_token = '';
            state.access_token = '';
            state.loggedInUser = {};
            state.isAuthenticated = false;
            state.wantTogo = '';
        },
        setWantToGo: function (state, wanToGo) {
            state.wantTogo = wanToGo;
        },
        releaseWantToGo: function (state) {
            state.wantTogo = '';
        },
    },
    actions: {
        /**
         * ログイン
         * @param state
         * @param commit
         * @param payload
         * @returns {Promise<void>}
         */
        // eslint-disable-next-line no-unused-vars
        logIn: async ({state, commit}, payload) => {
            const loginUrl = '/login';
            try {
                await axios.post(loginUrl, payload).then((response) => {
                    if (response.status === 200) {
                        commit('setRefreshToken', response.data.refresh_token);
                        commit('setAccessToken', response.data.access_token);
                        router.push({name: 'menu'});
                        let wantToGo = state.wantTogo
                        if ("" !== wantToGo && wantToGo.includes("/#/room/")) {
                            store.dispatch("releaseToGo")
                            location.href = wantToGo;
                        }
                    }
                });
            } catch (e) {
                throw new Error("can not login");
            }
        },
        /**
         * リフレッシュトークン
         * @param state
         * @param commit
         * @returns {Promise<void>}
         */
        refreshToken: async ({state, commit}) => {
            const refreshUrl = '/refresh_token';
            try {
                await axios
                    .post(refreshUrl, {
                        refresh_token: `${state.refresh_token}`,
                        access_token: `${state.refresh_token}`,
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            commit('setAccessToken', response.data.access_token);
                            commit('setRefreshToken', response.data.refresh_token);
                        }
                    });
            } catch (e) {
                console.error(e.response);
            }
        },
        /**
         * ログアウト
         * @param commit
         */
        logout: ({commit}) => {
            commit('clearUserData')
            router.push({name: 'home'});
        },
        /**
         * ユーザーIDを取得
         * @param state
         * @returns {any|{sub: number}}
         */
        get_user_id: ({state}) => {
            if ("" === state.access_token) {
                return {sub: 0}
            }
            const base64Url = state.access_token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
        },
        /**
         * 画像をリサイズ
         * @param commit
         * @param payload
         * @returns {Promise<string|null>}
         */
        // eslint-disable-next-line no-empty-pattern,no-unused-vars
        resizeImage: async ({commit}, payload) => {
            let imageData = payload.imageData;
            let width = payload.width;
            try {
                const context = document.createElement('canvas').getContext('2d')
                if (context == null) {
                    return null
                }
                // 画像のサイズを取得
                const image = await new Promise((resolve, reject) => {
                    const image = new Image()
                    image.addEventListener('load', () => resolve(image))
                    image.addEventListener('error', reject)
                    image.src = URL.createObjectURL(imageData)
                })
                const {naturalHeight: beforeHeight, naturalWidth: beforeWidth} = image
                // 変換後の高さと幅を算出
                const afterWidth = width
                const afterHeight = Math.floor(beforeHeight * (afterWidth / beforeWidth))
                // Canvas 上に描画
                context.canvas.width = afterWidth
                context.canvas.height = afterHeight
                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
                context.drawImage(image, 0, 0, beforeWidth, beforeHeight, 0, 0, afterWidth, afterHeight)
                // JPEGデータにして返す
                return context.canvas.toDataURL(`image/png`, 0.9)
            } catch (err) {
                console.error(err)
                return null
            }
        },
        /**
         * 行きたいURLを保存
         * @param commit
         */
        toGo: ({commit}) => {
            let loc = location.href
            commit('setWantToGo', loc)
        },
        /**
         * 行きたいURLを排除
         * @param commit
         */
        releaseToGo: ({commit}) => {
            commit('releaseWantToGo')
        },
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        accessToken: (state) => state.access_token,
        refreshToken: (state) => state.refresh_token,
        wantToGo: (state) => state.wantTogo,
    },
});

export default store;
