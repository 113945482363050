/* eslint-disable */
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import axiosSetUp from './interceptor/axios';
// eslint-disable-next-line
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line
import "bootstrap/dist/js/bootstrap.bundle"
// eslint-disable-next-line
import "./assets/style/common.css";
import VueStarRating from 'vue-star-rating'
import {vfmPlugin} from 'vue-final-modal'
import './registerServiceWorker'

import 'gitart-vue-dialog/dist/style.css'
import {GDialog} from 'gitart-vue-dialog'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import {createI18n} from 'vue-i18n'
//import FlagIcon from 'vue-flag-icon'
import VueGtag from "vue-gtag-next"; //ここ追加

if ('serviceWorker' in navigator) {
    window.onload = async () => {
        // Service Workerがactiveになったら、実行される
        const activeRegistration = await navigator.serviceWorker.ready
        // この段階では、まだcontrollerはnullのため、active.postMessageでメッセージを送信
        activeRegistration.active.postMessage({ action: 'claim' })
    }
}

const messages = {
    en: {
        home: {
            lead1: "Talk and Heal your loneliness.",
            lead2: "This service is in beta period.",
            lead3: "Paid coin purchases and withdrawals are not yet supported.",
            //lead1: "Talk and make money.<br/>Heal your loneliness.",
            head1: "As a host",
            p1: "Converse with guest users and cure your loneliness in Braama.",
            head2: "As a guest",
            p2: "Talk with host and other users and heal your loneliness with Braama.",
            register: "Register",
            get_client: "Get a client",
            client: "Win Download",
            client_mac: "Mac Download",
            login: "Sign In",
            home: "Home",
        },
        login: {
            forget_password: "I've forgot password...",
        },
        register: {
            register_user: "register user",
            email: "email",
            email_already: "email is already used.",
            password: "password",
            password_confirm: "password (confirm)",
            username: "username",
            username_already: "username is already used.",
            register: "Register",
            check_mail: "Check Your Mail",
            check_mail_p: "email confirm mail has been send.please check your mailbox.",
            verified: "email verified",
            verified_p: "email verified.thank you.",
            verified_fail: "verification of email is failed.",
            to_login: "to Sign In page",
        },
        hello: 'hello <br/>world',

        menu: {
            search_room: "Search Room",
        },
        block_user: {
            title: "Block Users",
        },
        buy_coins: {
            title: "Buy Coin",
        },
        info: {
            title: "Information",
        },
        my_session: {
            title: "My Sessions",
        },
        reset_pass: {
            message: "send password reset mail",
            sended: "Reset mail has been send.<br/> Check your email.",
        },
        reset: {
            message: "change password",
            to_login: "Sign In Page",
            changed: "Password changed.",
            change_failed: "failed.",
        },
        payout: {
            title: "Payout",
            you_have: "You have",
            earned: "earned coins",
            order: "make payout order",
            step: "step",
            is: "is",
            to_paypal: "to paypal account",
            with_address: "with address",
            and_tel: "and tel",
            sended: "payout order has been submitted.",
        },
        make_new_room: {
            title: "Make New Room",
        },
        my_rooms: {
            title: "My Rooms",
            make_new: "Make New Room",
        },
        room: {
            title: "Room",
        },
        support: {
            title: "Support",
            sended: "Support request submitted",
        },
        user_info: {
            title: "User Info Change",
        },
        common: {
            back: "Back",
            send: "Send",
            email: "email",
            password: "password",
            password_confirm: "password(confirm)",
            change: "Change",
            password_not_same: "password not same!",
            diamond: "diamond",
            year_month: "year month",
            process_date: "process date",
            voice: "voice sound",
            status: "status",
            paypal_email: "paypal email address",
            updated_at: "updated at",
            room_name: "room name",
            language: "language",
            member_limit: "member limit",
            cost: "cost(coin)",
            coin: "coin",
            duration: "duration(sec)",
            worktime: "worktime",
            description: "room description",
            create: "Create",
            home: "Home",
            roomid: "roomid",
            copy_uuid: "Copy UUID",
            rating: "rating",
            cost_only: "cost",
            sec: "sec",
            view_review: "View Review",
            edit: "Edit",
            cancel_edit: "Cancel Edit",
            reviews: "Reviews",
            close: "Close",
            copied: "Copied to clipboard",
            subject: "subject",
            body: "body",
            username: "username",
            username_already: "username is already used.",
            current_password: "current password",
            new_password: "new password",
            new_password_confirm: "new password(confirm)",
            users_room: "User's Rooms",
            invalid_change_attempt: "invalid change attempt",
            change_password: "Change Password",
            input_password: "input password",
            password_change_success: "password successfully changed",
            sessions: "sessions",
            view: "View",
            active_or_not: "active or not",
            joined: "joined",
            review_only: "Review",
            cancel: "Cancel",
            write_review: "Write Review",
            active: "active",
            inactive: "inactive",
            login_ng: "email or password is incorrect",
            copy_url: "copy URL"
        },
        component: {
            home: "Home",
            info: "Information",
            user_info_change: "User Info",
            my_room: "My Rooms",
            my_session: "My Sessions",
            block_users: "Block Users",
            buy_coin: "Buy Coins",
            buy_coin2: "Add Coins",
            buy_coin2_text: "Add Coins for Free",
            payout: "Payout",
            top: "Top",
            support: "Support",
            forum: "Forum",
            logout: "Logout",
            policy: "privacy policy",
            terms: "terms of service",
            delete_block: "Delete Block",
        }
    },
    ja: {
        home: {
//            lead1: "会話してお金を稼ごう<br/>孤独を癒そう",
            lead2: "サービスはベータ期間中です。",
            lead3: "※有料のコイン購入と出金はまだサポートされていません。",
            lead1: "会話して孤独を癒そう",
            head1: "ホストとして",
            p1: "ゲストユーザーと会話してBraamaで孤独を癒そう。",
            head2: "ゲストとして",
            p2: "ホストと会話して孤独を癒そう。",
            register: "ユーザー登録",
            get_client: "クライントを入手",
            client: "Win ダウンロード",
            client_mac: "Mac ダウンロード",
            login: "ログイン",
            home: "ホーム",
        },
        login: {
            forget_password: "パスワードを忘れた方はこちら",
        },
        register: {
            register_user: "ユーザー登録",
            email: "Eメール",
            email_already: "このEメールはすでに登録ずみです",
            password: "パスワード",
            password_confirm: "パスワード(確認)",
            username: "ユーザーネーム",
            username_already: "ユーザーネームはすでに登録ずみです",
            register: "登録",
            check_mail: "Eメール確認",
            check_mail_p: "Eメールを確認するためのメールを送信しました。",
            verified: "Eメール確認完了",
            verified_p: "Eメールが確認できました。ありがとうございます。",
            verified_fail: "Eメールの確認に失敗しました。",
            to_login: "ログインページへ",
        },
        hello: 'こんにちは、<br/>世界',

        menu: {
            search_room: "ルーム検索",
        },
        block_user: {
            title: "ブロックユーザー",
        },
        buy_coins: {
            title: "コイン購入",
        },
        info: {
            title: "インフォメーション",
        },
        my_session: {
            title: "マイセッション",
        },
        reset_pass: {
            message: "パスワードリセットメールを送信する",
            sended: "リセットメールを送信しました。<br/>メールをチェックしてください",
        },
        reset: {
            message: "パスワード変更",
            to_login: "ログインページへ",
            changed: "パスワードを変更しました",
            change_failed: "変更に失敗しました",
        },
        payout: {
            title: "出金",
            you_have: "ダイアモンドを",
            earned: "所有しています",
            order: "出金申し込みをします",
            step: "ステップ",
            is: "=",
            to_paypal: "を下記のペイパルアカウントに",
            with_address: "住所の入力が必要です",
            and_tel: "電話番号の入力が必要です",
            sended: "申し込みが送信されました",
        },
        make_new_room: {
            title: "ルーム作成",
        },
        my_rooms: {
            title: "マイルーム",
            make_new: "ルーム新規作成",
        },
        room: {
            title: "ルーム",
        },
        support: {
            title: "サポート",
            sended: "サポートリクエストを送信しました",
        },
        user_info: {
            title: "ユーザー情報",
        },
        common: {
            back: "戻る",
            send: "送信",
            email: "Eメール",
            password: "パスワード",
            password_confirm: "パスワード(確認)",
            change: "変更",
            password_not_same: "パスワードが一致しません",
            diamond: "ダイアモンド",
            year_month: "年月",
            process_date: "処理日",
            status: "状況",
            voice: "音声",
            paypal_email: "ペイパル Eメールアドレス",
            updated_at: "データ更新日時",
            room_name: "ルーム名",
            language: "言語",
            member_limit: "制限人数",
            cost: "費用(コイン)",
            coin: "コイン",
            duration: "時間(秒)",
            worktime: "稼働時間",
            description: "ルーム説明",
            create: "作成",
            home: "ホーム",
            roomid: "ルームID",
            copy_uuid: "UUIDをコピー",
            rating: "評価",
            cost_only: "費用",
            sec: "秒",
            view_review: '評価を見る',
            edit: "編集",
            cancel_edit: "編集中止",
            reviews: "評価",
            close: "閉じる",
            copied: "コピーしました",
            subject: "タイトル",
            body: "内容",
            username: "ユーザーネーム",
            username_already: "ユーザーネームはすでに登録ずみです",
            current_password: "現在のパスワード",
            new_password: "新しいパスワード",
            new_password_confirm: "新しいパスワード(確認)",
            users_room: "ユーザーのルーム",
            invalid_change_attempt: "不正な変更試行です",
            change_password: "パスワード変更",
            input_password: "パスワードを入力してください",
            password_change_success: "パスワード変更に成功しました",
            sessions: "セッション",
            view: "閲覧",
            active_or_not: "稼働中",
            joined: "参加日時",
            review_only: "評価",
            cancel: "中止",
            write_review: "評価を送信する",
            active: "稼働中",
            inactive: "休止中",
            login_ng: "Eメールまたはパスワードが間違っています",
            copy_url: "URLをコピー",
        },
        component: {
            home: "ホーム",
            info: "インフォメーション",
            user_info_change: "ユーザー情報",
            my_room: "マイルーム",
            my_session: "マイセッション",
            block_users: "ブロックユーザー管理",
            buy_coin: "コイン購入",
            buy_coin2: "コイン追加",
            buy_coin2_text: "無料でコイン追加",
            payout: "出金",
            top: "トップページ",
            support: "サポート",
            forum: "フォーラム",
            logout: "ログアウト",
            policy: "プライバシーポリシー",
            terms: "利用規約",
            delete_block: "ブロック解除",
        }
    }
}

let language = window.navigator.userLanguage || window.navigator.language;
if (language.indexOf('ja') === -1) {
    language = "en"
} else {
    language = "ja"
}

const i18n = createI18n({
    locale: language, // set locale
    messages, // set locale messages)
})

axiosSetUp()

const app = createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(vfmPlugin)
    .use(VueGtag, { property: { id: process.env.VUE_APP_GA, router } })
//    .use(FlagIcon)

app.component('star-rating', VueStarRating.default)
app.component('GDialog', GDialog)
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
app.mount('#app')

