import axios from 'axios';
import store from '../store';
import router from '../router';

/**
 * axiosに認証を追加し、リトライも行う
 */
export default function axiosSetUp() {
    // point to your API endpoint
    axios.defaults.baseURL = `${process.env.VUE_APP_API_ORIGIN}`;

    // Add a request interceptor
    axios.interceptors.request.use(
        function (config) {
            if (!config.url.endsWith('/refresh_token')
                && !config.url.includes("/api/user/password_reset_mail")
                && !config.url.includes("/api/user/password_reset")
                && !config.url.includes("/api/text") // テキスト
            ) {
                const token = store.getters.accessToken;
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            } else {
//                if(config.url.endsWith('/refresh_token')) {
//                    const token = store.getters.refreshToken;
//                    config.headers.refresh_token = `Bearer ${token}`;
//                }
            }
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function (error) {
            if(store.getters.refreshToken === "") {
                await router.push('/login');
                return;// Promise.reject(error);
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const originalRequest = error.config;
            if (
                error.response.status === 403 &&
                originalRequest.url.includes('/refresh_token')
            ) {
                await router.push('/login');
                return Promise.reject(error);
            } else if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                await store.dispatch('refreshToken');
                return axios(originalRequest);
            }
            return Promise.reject(error);
        }
    );
}
