<template>
  <link rel="preload" as="image" :href="require('@/assets/yt.webp')"/>

  <router-view/>

  <GDialog v-model="updateExists" class="common-bg">
    <div class="dialog">
      <h2 class="text-center">
        update exists
      </h2>
      <div style="text-align: center">
        <button type="click" class="btn btn-lg btn-success app-green" v-on:click="refreshApp">Reload</button>
      </div>
    </div>
  </GDialog>

  <vue-cookie-accept-decline
      :debug="false"
      :disableDecline="true"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
      elementId="myPanel1"
      position="bottom-left"
      ref="myPanel1"
      transitionName="slideFromBottom"
      type="floating"
  >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      We use cookies to ensure you get the best experience on our website.
    </template>

    <!-- Optional -->
    <template #declineContent>Opt Out</template>

    <!-- Optional -->
    <template #acceptContent>Got It!</template>
  </vue-cookie-accept-decline>

</template>
<script>
import update from './mixin/update'
import router from "@/router";

export default {
  name: 'App',
  mixins: [update],
  mounted() {
//    this.loadScript(`https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=USD`)
  },
  methods: {
    cookieClickedAccept: function() {},
    cookieClickedDecline: function() {
      window.localStorage.removeItem("vue-cookie-accept-decline-myPanel1")
      router.push("/");
    },
    loadScript: function (url) {
      const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
        const s = document.createElement('script');
        s.setAttribute('src', url);
        document.head.insertBefore(s, document.head.firstElementChild);
      }
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/*@import 'https://cdn.jsdelivr.net/npm/gitart-vue-dialog@1.2.1/dist/style.css';*/
.g-dialog-content {
  max-width: 70%;
  background-color: #1B1E26 !important;
}

.dialog {
  padding: 30px 20px;
  background-color: #1B1E26 !important;
  border: 1px solid #2DAF5D;
  border-radius: 8px;
}

h2 {
  margin: 0 0 20px;
}
</style>
